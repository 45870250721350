import { makeAutoObservable, runInAction } from "mobx";

export default class TimeStore {
  customName = '';
  selectedTimeIn = null;
  selectedTimeOut = null;
  timeInDescription = null;
  timeOutDescription = null;
  defaultCode = 0;
  selectedOptions = null;
  customTimes = {
    Sunday: { in: "00:00:00", out: "00:00:00" },
    Monday: { in: "00:00:00", out: "00:00:00" },
    Tuesday: { in: "00:00:00", out: "00:00:00" },
    Wednesday: { in: "00:00:00", out: "00:00:00" },
    Thursday: { in: "00:00:00", out: "00:00:00" },
    Friday: { in: "00:00:00", out: "00:00:00" },
    Saturday: { in: "00:00:00", out: "00:00:00" },
  };
  dayIndex = null;

  customShiftObj = {
    code: `${"DefaultCode"}${this.defaultCode + 1}`,
    attendancePolicyId: "4055e97c-50ae-446d-9311-a4e57938a309",
    description: `${this.customName}`,
    remarks: `custom`,
    type: 0,
    status: 1,
    shiftCodeItems: Object.keys(this.customTimes).map((day, index) => ({
      dayOfWeek: index,
      timeIn: this.customTimes[day].in,
      timeOut: this.customTimes[day].out,
      shiftHours: 0,
      isWithBreak: false,
      breakHours: 0,
      nightDifferential: 0,
      shiftCodeItemType: this.customTimes[day].in === "00:00:00" ? 1 : 0,
    })),
  };

  weekEndShiftObj = {
    code: `${"DefaultCode"}${this.defaultCode + 1}`,
    attendancePolicyId: "4055e97c-50ae-446d-9311-a4e57938a309",
    description: `Weekend ${this.timeInDescription} - ${this.timeOutDescription} (Regular)`,
    remarks: `weekend`,
    type: 0,
    status: 1,
    shiftCodeItems: [
      {
        dayOfWeek: 0,
        timeIn: this.selectedTimeIn,
        timeOut: this.selectedTimeOut,
        shiftHours: 0,
        isWithBreak: false,
        breakHours: 0,
        nightDifferential: 0,
        shiftCodeItemType: 0,
      },
      {
        dayOfWeek: 6,
        timeIn: this.selectedTimeIn,
        timeOut: this.selectedTimeOut,
        shiftHours: 0,
        isWithBreak: false,
        breakHours: 0,
        nightDifferential: 0,
        shiftCodeItemType: 0,
      },
    ],
  };

  dailyShiftObj = {
    code: `${"DefaultCode"}${this.defaultCode + 1}`,
    attendancePolicyId: "4055e97c-50ae-446d-9311-a4e57938a309",
    description: `Monday - Friday ${this.timeInDescription} - ${this.timeOutDescription} (Regular) & Saturday - Sunday (Rest Day)`,
    remarks: `daily`,
    type: 0,
    status: 1,
    shiftCodeItems: [
      {
        dayOfWeek: 1,
        timeIn: this.selectedTimeIn,
        timeOut: this.selectedTimeOut,
        shiftHours: 0,
        isWithBreak: false,
        breakHours: 0,
        nightDifferential: 0,
        shiftCodeItemType: 0,
      },
      {
        dayOfWeek: 2,
        timeIn: this.selectedTimeIn,
        timeOut: this.selectedTimeOut,
        shiftHours: 0,
        isWithBreak: false,
        breakHours: 0,
        nightDifferential: 0,
        shiftCodeItemType: 0,
      },
      {
        dayOfWeek: 3,
        timeIn: this.selectedTimeIn,
        timeOut: this.selectedTimeOut,
        shiftHours: 0,
        isWithBreak: false,
        breakHours: 0,
        nightDifferential: 0,
        shiftCodeItemType: 0,
      },
      {
        dayOfWeek: 4,
        timeIn: this.selectedTimeIn,
        timeOut: this.selectedTimeOut,
        shiftHours: 0,
        isWithBreak: false,
        breakHours: 0,
        nightDifferential: 0,
        shiftCodeItemType: 0,
      },
      {
        dayOfWeek: 5,
        timeIn: this.selectedTimeIn,
        timeOut: this.selectedTimeOut,
        shiftHours: 0,
        isWithBreak: false,
        breakHours: 0,
        nightDifferential: 0,
        shiftCodeItemType: 0,
      },
    ],
  };

  constructor() {
    makeAutoObservable(this);
  }

  convertDayToIndex = (dayString) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek.indexOf(dayString);
  };

  setCustomTimeInObj = (day, time) => {
    runInAction(() => {
      const dayIndex = this.convertDayToIndex(day);
      this.customTimes[day].in = this.convertToUTC(time);
      this.updateCustomShiftObj();
      this.dayIndex = dayIndex;
    });
  };

  setCustomTimeOutObj = (day, time) => {
    runInAction(() => {
      const dayIndex = this.convertDayToIndex(day);
      this.customTimes[day].out = this.convertToUTC(time);
      this.updateCustomShiftObj();
      this.dayIndex = dayIndex;
    });
  };

  setTimeToNull = () => {
    runInAction(() => {
      this.selectedTimeIn = null;
      this.selectedTimeOut = null;
      this.timeInDescription = null;
      this.timeOutDescription = null;
    });
  };

  setSelectedTimeIn = (time) => {
    runInAction(() => {
      this.selectedTimeIn = this.convertToUTC(time);
      this.timeInDescription = time;
      this.updateDailyObj();
      this.updateWeekendObj();
    });
  };

  setSelectedTimeOut = (time) => {
    runInAction(() => {
      this.selectedTimeOut = this.convertToUTC(time);
      this.timeOutDescription = time;
      this.updateDailyObj();
      this.updateWeekendObj();
    });
  };

  setDefaultCode = (number) => {
    runInAction(() => {
      this.defaultCode = number;
      this.updateDailyObj();
      this.updateWeekendObj();
    });
  };

  setSelectedOptions = (data) => {
    runInAction(() => {
      this.selectedOptions = data;
    });
  };

  updateCustomShiftObj = () => {
    runInAction(() => {
      this.customShiftObj = {
        code: `${"DefaultCode"}${this.defaultCode + 1}`,
        attendancePolicyId: "4055e97c-50ae-446d-9311-a4e57938a309",
        description: `${this.customName}`,
        remarks: `custom`,
        type: 0,
        status: 1,
        shiftCodeItems: Object.keys(this.customTimes).map((day, index) => ({
          dayOfWeek: index,
          timeIn: this.customTimes[day].in,
          timeOut: this.customTimes[day].out,
          shiftHours: 0,
          isWithBreak: false,
          breakHours: 0,
          nightDifferential: 0,
          shiftCodeItemType: this.customTimes[day].in === "00:00:00" ? 1 : 0,
        })),
      };
    });
  };

  updateWeekendObj = () => {
    runInAction(() => {
      this.weekEndShiftObj = {
        code: `${"DefaultCode"}${this.defaultCode + 1}`,
        attendancePolicyId: "4055e97c-50ae-446d-9311-a4e57938a309",
        description: `Weekend ${this.timeInDescription} - ${this.timeOutDescription} (Regular)`,
        remarks: `weekend`,
        type: 0,
        status: 1,
        shiftCodeItems: [
          {
            dayOfWeek: 0,
            timeIn: this.selectedTimeIn,
            timeOut: this.selectedTimeOut,
            shiftHours: 0,
            isWithBreak: false,
            breakHours: 0,
            nightDifferential: 0,
            shiftCodeItemType: 0,
          },
          {
            dayOfWeek: 6,
            timeIn: this.selectedTimeIn,
            timeOut: this.selectedTimeOut,
            shiftHours: 0,
            isWithBreak: false,
            breakHours: 0,
            nightDifferential: 0,
            shiftCodeItemType: 0,
          },
        ],
      };
    });
  };

  updateDailyObj = () => {
    runInAction(() => {
      this.dailyShiftObj = {
        code: `${"DefaultCode"}${this.defaultCode + 1}`,
        attendancePolicyId: "4055e97c-50ae-446d-9311-a4e57938a309",
        description: `Monday - Friday ${this.timeInDescription} - ${this.timeOutDescription} (Regular) & Saturday - Sunday (Rest Day)`,
        remarks: `daily`,
        type: 0,
        status: 1,
        shiftCodeItems: [
          {
            dayOfWeek: 1,
            timeIn: this.selectedTimeIn,
            timeOut: this.selectedTimeOut,
            shiftHours: 0,
            isWithBreak: false,
            breakHours: 0,
            nightDifferential: 0,
            shiftCodeItemType: 0,
          },
          {
            dayOfWeek: 2,
            timeIn: this.selectedTimeIn,
            timeOut: this.selectedTimeOut,
            shiftHours: 0,
            isWithBreak: false,
            breakHours: 0,
            nightDifferential: 0,
            shiftCodeItemType: 0,
          },
          {
            dayOfWeek: 3,
            timeIn: this.selectedTimeIn,
            timeOut: this.selectedTimeOut,
            shiftHours: 0,
            isWithBreak: false,
            breakHours: 0,
            nightDifferential: 0,
            shiftCodeItemType: 0,
          },
          {
            dayOfWeek: 4,
            timeIn: this.selectedTimeIn,
            timeOut: this.selectedTimeOut,
            shiftHours: 0,
            isWithBreak: false,
            breakHours: 0,
            nightDifferential: 0,
            shiftCodeItemType: 0,
          },
          {
            dayOfWeek: 5,
            timeIn: this.selectedTimeIn,
            timeOut: this.selectedTimeOut,
            shiftHours: 0,
            isWithBreak: false,
            breakHours: 0,
            nightDifferential: 0,
            shiftCodeItemType: 0,
          },
        ],
      };
    });
  };

  setCustomName = (name) => {
    this.customName = name;
  };

  convertToUTC = (time) => {
    console.log(`Input time: ${time}`);
    // Regular expression to parse 12-hour time format with AM/PM
    const timePattern = /(\d{1,2}):(\d{2})\s?(AM|PM)/i;
    const match = time.match(timePattern);

    console.log(`Match: ${match}`);
    if (!match) {
      console.log("Invalid time format");
      return "00:00:00"; // Fallback to a default value if parsing failed
    }

    let [hours, minutes, period, format] = match;
    console.log(
      `Parsed time: hours=${hours}, minutes=${minutes}, period=${period} format=${format}`
    );
    hours = parseInt(hours);
    minutes = parseInt(period);

    // Convert 12-hour format to 24-hour format
    if (format.toUpperCase() === "PM" && hours < 12) {
      hours += 12;
    } else if (format.toUpperCase() === "AM" && hours === 12) {
      hours = 0;
    }

    console.log(`Converted time: hours=${hours}, minutes=${minutes}`);

    // Create a new Date object using the current date and the provided time
    const localDate = new Date();
    localDate.setHours(hours);
    localDate.setMinutes(minutes);
    localDate.setSeconds(0);

    console.log(`Local date: ${localDate}`);

    // Get the timezone offset in milliseconds
    const timezoneOffset = localDate.getTimezoneOffset() * 60000;

    // Calculate the UTC time in milliseconds
    const utcTime = localDate.getTime() - timezoneOffset;

    // Create a new Date object using the UTC time
    const utcDate = new Date(utcTime);

    console.log(`UTC date: ${utcDate}`);

    // Extract the time part in "HH:MM:SS" format
    const hoursUTC = String(utcDate.getUTCHours()).padStart(2, "0");
    const minutesUTC = String(utcDate.getUTCMinutes()).padStart(2, "0");
    const secondsUTC = String(utcDate.getUTCSeconds()).padStart(2, "0");

    const utcTimeString = `${hoursUTC}:${minutesUTC}:${secondsUTC}`;
    console.log(`UTC time string: ${utcTimeString}`);

    return utcTimeString;
  };
}
