import React, {useState} from "react";
import { Row, Col, Button } from "antd";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import UploadImage from "../../common/UploadImage";
import UploadReviewImage from "../../common/UploadReviewImage";
import { toast } from "react-toastify";
//import PropTypes from "prop-types";
import { useEmployee } from "../../../app/hooks/useEmployee";

const UploadPhotoModal = () => {
  const [selectedDepartment] = useState();
  const { mutate } = useEmployee({
    departmentId: selectedDepartment ?? "",
    pageNumber: 0,
    pageSize: 9999,
  });
  const { modalStore, employeeStore } = useStore();
  const { setUpdatePhotoModalVisible } = modalStore;
  const { image, uploadPhotoObject, setImage, setFileList } = employeeStore;
  const { patch } = useEmployee();

  const handleSave = async () => {
    // Check if the image is not null before patching the employee
    // Check if the image is not null before patching the employee
    if (image) {
      // Use the patch function to update the employee with the new photo
     
      await patch(uploadPhotoObject);
      setImage('');
      setFileList([]);
      setUpdatePhotoModalVisible(false);
      // Close the modal after saving
      //hideModal();
      //onSubmit();
      mutate();
      toast.success("Successfully Added Photo", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // Display a toast message when no image is selected
      toast.error("Please Select an image", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <Row justify={"center"}>
        <Col>
          <UploadImage />
          <Row justify={"center"}>
            <Col>
              <Button onClick={handleSave}>Save</Button>
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: 20 }}>
          <h2 className="core-form-title">
            <span style={{ color: "#FF7201" }}>Upload </span>
            Profile Photo
          </h2>
          <p className="upload-img-txt">
            File Formats Accepted
            <span className="upload-img-span">PNG, JPG, JPEG</span>
          </p>
          <p className="upload-img-txt">
            Max. file size <span className="upload-img-span">20 MB</span>
          </p>
        </Col>
        <Col>
          <UploadReviewImage />
        </Col>
      </Row>
    </>
  );
};

export default observer(UploadPhotoModal);
