import React, { useState } from "react";
import { Table, ConfigProvider, Button } from "antd";
import { observer } from "mobx-react-lite";
import { useOvertimeApplication } from "../../app/hooks/useOvertimeApplication";
import moment from "moment/moment";
import UpdateOvertimeStatusForm from "./modal/UpdateOvertimeStatusForm";

// import ViewBtn from "./buttons/ViewBtn";

const AttendanceOvertime = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading, mutate } = useOvertimeApplication({
    pageNumber: pageNumber,
    pageSize: 10,
    sort: "date_descend",
  });

  const columns = [
    {
      title: "Employee Number",
      dataIndex: "employeeIdNumber",
      key: "employeeIdNumber",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Work Type",
      dataIndex: "otWorkTypeDesc",
      key: "otWorkTypeDesc",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Date Filed",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {moment(text).format("MMMM DD, YYYY")}
        </p>
      ),
    },
    {
      title: "From",
      dataIndex: "timeStart",
      key: "timeStart",
      align: "center",
      render: (text) => {
        const [hour] = text.split(":");
        const ampm = parseInt(hour) >= 12 ? "PM" : "AM";

        return (
          <p style={{ color: "black", fontSize: 17 }}>{`${text} ${ampm}`}</p>
        );
      },
    },
    {
      title: "To",
      dataIndex: "timeEnd",
      key: "timeEnd",
      align: "center",
      render: (text) => {
        const [hour] = text.split(":");
        const ampm = parseInt(hour) >= 12 ? "PM" : "AM";

        return (
          <p style={{ color: "black", fontSize: 17 }}>{`${text} ${ampm}`}</p>
        );
      },
    },
    {
      title: "Total Hours",
      dataIndex: "totalHours",
      key: "totalHours",
      align: "center",
      render: (text) => {
        const daysMatch = text.match(/(\d+)\s+days/);
        const hoursMatch = text.match(/(\d+)\s+hours/);

        // Extract the numeric values or default to 0
        const days = daysMatch ? parseInt(daysMatch[1]) : 0;
        const hours = hoursMatch ? parseInt(hoursMatch[1]) : 0;

        // Calculate total hours by converting days to hours and adding the remaining hours
        const totalHours = days * 24 + hours;

        return <p style={{ color: "black", fontSize: 17 }}>{totalHours} hrs</p>;
      },
    },
    {
      title: "Date Rendered",
      dataIndex: "actualOTDate",
      key: "actualOTDate",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {moment(text).format("MMMM DD, YYYY")}
        </p>
      ),
    },
    {
      title: "Approved By",
      dataIndex: "approvedByUserName",
      key: "approvedByUserName",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Status",
      key: "statusDesc",
      dataIndex: "statusDesc",
      align: "center",
      render: (_, record) => (
        <UpdateOvertimeStatusForm
          record={record}
          onSubmit={() => mutate()}
        />
      ),
    },


    
  ];

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "white",
          margin: 16,
          boxShadowSecondary:
            "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          borderRadius: 8,
        },
        components: {
          Table: {
            headerBg: "#FF7201",
            headerColor: "white",
            headerSplitColor: "#2C2C2C",
          },
        },
      }}
    >
      <Table
        rowKey="employeeId"
        columns={columns}
        dataSource={data}
        loading={isLoading}
        style={{ width: "1884px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          type="primary"
          onClick={handlePreviousPage}
          style={{ marginRight: "10px" }}
        >
          Previous Page
        </Button>
        <Button type="primary" onClick={handleNextPage}>
          Next Page
        </Button>
      </div>
    </ConfigProvider>
  );
};

export default observer(AttendanceOvertime);
