import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col, Space } from "antd";
import AttendanceOvertime from "./AttendanceOvertime";
import ExportBtn from "./buttons/ExportBtn";
import OvertimeFilterBtn from "./buttons/OvertimeFilter";

const OvertimeBody = () => {
  return (
    <>
      <Row>
        <Col span={22}>
          <Row justify={"space-between"}>
            <Col>
              <h1>Overtime</h1>
            </Col>
            <Col>
              <Space style={{ marginTop: 20, marginLeft: "50%" }}>
                <ExportBtn />
                <OvertimeFilterBtn />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-evenly"}>
            <Col>
              <AttendanceOvertime />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default observer(OvertimeBody);
