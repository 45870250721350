import React, { useEffect, useState } from "react";
import { Table, ConfigProvider, Button } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from '../../../app/stores/store';
import { useAttendance } from "../../../app/hooks/useAttendance";
import moment from "moment";
import { api } from "../../../app/services/api";





const ExportList = () => {
  const { attendaceStore } = useStore();
  const {
    startDate,
    endDate,
    employeeId,
  } = attendaceStore;

  const { data } = useAttendance({
    employeeId: employeeId ?? "",
    startDate: startDate ?? "",
    endDate: endDate ?? "",
    sort: "date_descend",
    pageSize: 50,
    pageNumber: 1,
  });

  const [finalData, setFinalData] = useState([])
  const getAttendance = async () => {
    setFinalData([])
    try {
      let pageNumber = 1;
      let allData = [];
      do {
        // Build query parameters
        const params = new URLSearchParams({
          employeeId: employeeId ?? "",
          startDate: startDate || "",
          endDate: endDate || "",
          pageNumber: pageNumber,
          pageSize: 50,
        }).toString();
  
        // Construct the full URL with query parameters
        const url = `/system-admin/attendance-records?${params}`;
        
        const response = await api.get(url);
        // Append the data to allData
        allData = allData.concat(response);
  
        // Increment the page number for the next loop iteration
        pageNumber++;
      } while (allData?.length === 50); // Continue while full pages are returned
      const employeeOnlyList = allData.filter(entry => entry.employeeId === employeeId);
      setFinalData(employeeOnlyList)
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      // throw error;
    }
  };

  useEffect(() => {
    getAttendance();
  }, [startDate, endDate, data]);

  const formatTime = (timeString) => {
    const timeParts = timeString.split(":");
    let hours = parseInt(timeParts[0], 10);
    const minutes = timeParts[1];
    const seconds = timeParts[2];

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (00:00:00) as 12 AM

    return `${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const formatDate = (value) => {
    return moment(value).format("dddd, MMMM D, YYYY");
  };

  const columns = [
    {
      title: "Employee Number", // Changed title here
      dataIndex: "employeeNumber", // Changed dataIndex here
      key: "employeeNumber",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 12 }}>{text}</p>,
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 12 }}>{text}</p>,
    },
    {
      title: "Date",
      dataIndex: "dateIn",
      key: "dateIn",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 12 }}>{formatDate(text)}</p>
      ),
    },
    {
      title: "Time in",
      dataIndex: "timeIn",
      key: "timeIn",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 12 }}>
          {text !== "00:00:00" ? <p>{formatTime(text)}</p> : null}
        </p>
      ),
    },
    {
      title: "Time Out",
      key: "timeOut",
      dataIndex: "timeOut",
      render: (text) => (
        <p style={{ color: "black", fontSize: 12 }}>
          {text !== "00:00:00" ? <p>{formatTime(text)}</p> : null}
        </p>
      ),
      align: "center",
    },
    {
      title: "Location In",
      key: "location",
      dataIndex: "timeInLocation",
      render: (text, record) => (
        <p style={{ color: "black", fontSize: 12 }}>
          {record.timeInLocation ? record.timeInLocation : null}
        </p>
      ),
      align: "center",
    },
    {
      title: "Location Out",
      key: "location",
      dataIndex: "timeOutLocation",
      render: (text, record) => (
        <p style={{ color: "black", fontSize: 12 }}>
          {record.timeOutLocation ? record.timeOutLocation : null}
        </p>
      ),
      align: "center",
    },
    {
      title: "Project",
      key: "",
      dataIndex: "",
      render: () => <p style={{ color: "black", fontSize: 12 }}></p>,
      align: "center",
    },
    {
      title: "Work Hours",
      key: "workHours",
      dataIndex: "workHours",
      render: (text) => <p style={{ color: "black", fontSize: 12 }}>{text}</p>,
      align: "center",
    },
  ];
  
  const exportToCSV = () => {
    try {
      if (!finalData){
        alert("No data to export for the selected date range.");
        return
      }
      const transformDataByEmployee = (rawData) => {
        // convert raw data to grouped data per employee
        const groupedData = {};
        rawData.forEach((entry) => {
      
          const convertTimeIn = () => {
            const regex = /^(\d{2}:\d{2}\s?[APM]{2})/;
            const match = entry.workHours.match(regex);
            return match ? match[1] : null;
          };

          const workTimeIn = convertTimeIn();

          const { employeeId, employeeNumber, employeeName, officialTimeIn = workTimeIn, gracePeriod = null, dateIn, timeIn, timeOut} = entry;
          if (!groupedData[employeeId]) {
            groupedData[employeeId] = {
              employeeId : employeeId || null,
              employeeNumber : employeeNumber || null,
              employeeName : employeeName || null,
              officialTimeIn : officialTimeIn || null,
              gracePeriod : gracePeriod || null,
              attendance: {},
            };
          }
          groupedData[employeeId].attendance[dateIn] = {
            timeIn,
            timeOut,
            onBreak: null,
            absent: null,
            leave: null,
            offset: null
          };
        });
        const transformedData = Object.values(groupedData);
        return { transformedData };
      };
      const transformedData = transformDataByEmployee(finalData);

      // Collect all unique dates from the data within the selected date range
      const uniqueDatesSet = new Set();
      finalData.forEach((entry) => {
        const entryDate = moment(entry.dateIn);
        if (
          entryDate.isSameOrAfter(moment(startDate), "day") &&
          entryDate.isSameOrBefore(moment(endDate), "day")
        ) {
          uniqueDatesSet.add(entry.dateIn);
        }
      });

      const filteredDates = Array.from(uniqueDatesSet).sort((a, b) =>
        moment(a).diff(moment(b))
      );

      if (filteredDates.length === 0) {
        alert("No data to export for the selected date range.");
        return;
      }

      // Create headers based on filtered dates
      const headers = [
        "Employee Number",
        "Employee Name",
        "Official Time In",
        "Grace Period/Flexi/Cut-off",
        "Work Hours",
        ...filteredDates.flatMap((date) => [
          `${date}_IN`,
          `${date}_OUT`,
          `${date}_OB`,
          `${date}_ABSENT`,
          `${date}_LEAVE`,
          `${date}_OFFSET`,
          `${date}_LATE/S`,
          `${date}_FREQ`,
        ]),
      ].join(",");

      const csvRows = [headers];

      // Create CSV rows for each employee
      transformedData.transformedData.forEach((employee) => {
        const attendanceByDate = filteredDates.map((date) => {
          const attendance = employee.attendance[date] || {};
            attendance.late = employee.officialTimeIn 
            ? moment(attendance.timeIn, "HH:mm:ss").isAfter(moment(employee.officialTimeIn, "HH:mm:ss")) 
            ? moment.utc(moment(attendance.timeIn, "HH:mm:ss").diff(moment(employee.officialTimeIn, "HH:mm:ss"))).format("HH:mm")
            : "0:00"
            : "0:00"; 
  
          return [
            attendance.timeIn || "0:00",
            attendance.timeOut || "0:00",
            attendance.onBreak || "0:00",
            attendance.absent || "0",
            attendance.leave || "0",
            attendance.offset || "0:00",
            attendance.late || "0:00",
            attendance.late === "0:00" ? "0" : "1",
          ].join(",");
        });


        const row = [
          employee.employeeNumber,
          employee.employeeName,
          employee.officialTimeIn || "N/A",
          employee.gracePeriod || "N/A",
          employee.workHours || "N/A",
          ...attendanceByDate,
        ].join(",");

        csvRows.push(row);
      });


      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `attendance_data_${startDate}-${endDate}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } 
    catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            margin: 16,
            boxShadowSecondary:
              "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: 8,
            padding: 0,
          },
          components: {
            Table: {
              headerBg: "#FF7201",
              headerColor: "white",
              headerSplitColor: "#2C2C2C",
            },
          },
        }}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={finalData || []} // Ensure data is not undefined
          // loading={isLoading}
          scroll={{ x: 2000 }}  // Set the total width for horizontal scrolling
          style={{ width: "100%" }}
          pagination={{
            pageSize: 10,
          }}
          
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Button
            type="primary"
            onClick={() => exportToCSV()}
            style={{ background: "#FF7201", color: "#FFFFFF" }}
          >
            Export to CSV
          </Button>
        </div>
      </ConfigProvider>
    </>
  );
};



export default observer(ExportList);
