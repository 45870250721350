import React, { useState } from "react";
import { Button, Modal, Form, Input, Select } from "antd";
import PropTypes from "prop-types";
import { useOvertimeApplication } from "../../../app/hooks/useOvertimeApplication";
import { useOvertimeStatus } from "../../../app/hooks/enums/useOtStatus";
import { toast } from "react-toastify";

const UpdateOvertimeStatusForm = ({ record, onSubmit }) => {
  UpdateOvertimeStatusForm.propTypes = {
    record: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { update } = useOvertimeApplication();
  const overtimeStatuses = useOvertimeStatus();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#CFC700";
      case "Approved":
      case "ApprovedOffset":
      case "Paid":
        return "#008C27";
      case "Rejected":
      case "InvalidOffset":
        return "#FF0000";
      default:
        return "black";
    }
  };

  const handleSubmit = async (values) => {
    try {
      const updateOTStatusObj = {
        id: record?.id,
        status: values.status,
        adminRemarks: values.adminRemarks,
      };

      await update(updateOTStatusObj)
        .then(() => {
          onSubmit();
          toast.success("Successfully updated status", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsModalOpen(false);
          form.resetFields();
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button 
        onClick={showModal} 
        style={{ color: "white", backgroundColor: getStatusColor(record.statusDesc)}}
      >
        {record.statusDesc}
      </Button>
      <Modal
        footer={null}
        closable={false}
        style={{ top: 110 }}
        width={900}
        open={isModalOpen}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            status: 0,
            adminRemarks: '',
          }}
        >
          <Form.Item label="Reason" name="reason">
            <p>{record.reason}</p>
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Please select a status" }]}
          >
            <Select>
              {overtimeStatuses.data?.map((status) => (
                <Select.Option key={status.value} value={status.value}>
                  {status.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Admin Remarks"
            name="adminRemarks"
            rules={[{ required: true, message: "Please add remarks" }]}
          >
            <Input.TextArea rows={4} placeholder="Enter remarks" />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "green", color: "white", margin: "10px" }}
              >
                ACCEPT
              </Button>
              <Button
                type="primary"
                danger
                style={{ margin: "10px" }}
                onClick={() => setIsModalOpen(false)}
              >
                CANCEL
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateOvertimeStatusForm;
