import React from "react";
import { Form, Input, Button, Row, Col, Select, Divider } from "antd";
//import UploadImage from "../../common/UploadImage";
//import UploadReviewImage from "../../common/UploadReviewImage";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { useDepartment } from "../../../app/hooks/useDepartment";
import { usePositions } from "../../../app/hooks/usePositions";
import { useRegions } from "../../../app/hooks/useRegions";
import { useCurrentUser } from "../../../app/hooks/useCurrentUser";

import AddPositionForm from "../forms/AddPositionForms";
import AddDepartmentForm from "../forms/AddDepartmentForms";

const { Option } = Select;

const EmploymentInfoForm = ({ onNext, onPrev, formData }) => {
  const [form] = Form.useForm();
  form.setFieldsValue(formData);
  //const [selectedDepartment, setSelctedDepartment] = useState();
  const { data: departmentList, mutate: mutates } = useDepartment({
    pageNumber: 0,
    pageSize: 9999,
    sort: "date_descend",
  });
  const { data: positionList, mutate } = usePositions({
    pageNumber: 0,
    pageSize: 9999,
    sort: "date_descend",
  });
  const { data: regionList } = useRegions();
  const { role } = useCurrentUser();

  EmploymentInfoForm.propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
  };

  return (
    <>
      <Form form={form} onFinish={onNext} layout="vertical">
        <h2 className="core-form-title">
          II. <span style={{ color: "#FF7201" }}>Employment </span>Information
        </h2>

        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="position"
              label="Position:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    {role.includes("Super Admin") ? (
                      <AddPositionForm
                        onSubmit={() => {
                          mutate();
                        }}
                      />
                    ) : null}
                  </>
                )}
                options={positionList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employee Number:"
              name="employeeNum"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date Hired:"
              name="dateHired"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Work Assignment:"
              name="workAss"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                placeholder="Region"
                allowClear
                options={regionList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"}>
          <Col span={8}>
            <Form.Item
              label="Employment Status:"
              name="employmentStatus"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="JobOrder">Job Order</Option>
                <Option value="ProjectBased">Project Based</Option>
                <Option value="Regular">Regular</Option>
                <Option value="Separated">Separated</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Pre Employment Requirements:"
              name="preEmployRequirements"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Active or Inactive:"
              name="activeOrInactive"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
                <Option value="Blocked">Blocked</Option>
                <Option value="Separated">Separated</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="department"
              label="Department:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    {role.includes("Super Admin") ? (
                      <AddDepartmentForm
                        onSubmit={() => {
                          mutates();
                        }}
                      />
                    ) : null}
                  </>
                )}
                options={departmentList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Type:"
              name="contractType"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="NotApplicable">Not Applicable</Option>
                <Option value="ShortTerm">Short Term</Option>
                <Option value="LongTerm">Long Term</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Next
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={onPrev}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(EmploymentInfoForm);
