import axios from "axios";

axios.defaults.baseURL =
  "https://api.aaicompass.com/api/v1";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseBody = (response) => response.data;

export const api = {
  get: (url, config) => axios.get(url, config).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  patch: (url, body) => axios.patch(url, body).then(responseBody),
  delete: (url) => axios.delete(url).then(responseBody),
  postForm: async (url, file) => {
    const formData = new FormData();
    formData.append("File", file);
    const response = await axios
      .post(url, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
    return responseBody(response);
  },
};
