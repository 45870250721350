import React, { useState } from "react";
import { Form, Button, Row, Col, Select, Checkbox, Input } from "antd";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useStore } from "../../app/stores/store";
import TimePickerBtn from "../dashboard/attendance-gallery/buttons/TimePickerBtn";
import { useShiftCode } from "../../app/hooks/useShiftCode";
import CustomTimePickerBtn from "../dashboard/attendance-gallery/buttons/CustomTimePickerBtn";

const ShiftForm = () => {
  const [form] = Form.useForm();
  const { createShift, mutate } = useShiftCode();

  // const { data } = useShift();
  const { shiftStore, timeStore } = useStore();
  const {
    selectedTimeIn,
    selectedTimeOut,
    setSelectedOptions,
    setTimeToNull,
    setCustomName,
    dailyShiftObj,
    weekEndShiftObj,
    customShiftObj,
    customTimes,
  } = timeStore;
  const { setCreateShiftModalVisible } = shiftStore;
  const [check, setCheck] = useState(true);
  const [checkboxState, setCheckboxState] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const [selectedOption, setSelectedOption] = useState(null);

  // console.log(selectedTimeIn);
  // console.log(selectedTimeOut)
  const handleCheckboxChange = (e, value) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [value]: e.target.checked,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption === "daily") {
      setSelectedOptions("daily");
      setCheckboxState({
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: false,
        Sunday: false,
      });
      setCheck(false);
    } else if (selectedOption === "weekend") {
      setSelectedOptions("weekend");
      setCheckboxState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: true,
        Sunday: true,
      });
      setCheck(false);
    } else if (selectedOption === "custom") {
      setSelectedOptions("custom");
      setCheckboxState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      });
      setCheck(true);
    } else {
      // handle the case when no option is selected
      setCheckboxState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      });
      setCheck(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (selectedOption === "daily") {
        await createShift(dailyShiftObj).then(() => {
          mutate();
          setCreateShiftModalVisible(false);
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeToNull();
        });
      } else if (selectedOption === "weekend") {
        await createShift(weekEndShiftObj).then(() => {
          mutate();
          setCreateShiftModalVisible(false);
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeToNull();
        });
      } else if (selectedOption === "custom") {
        await createShift(customShiftObj).then(() => {
          mutate();
          setCreateShiftModalVisible(false);
          setCustomName("");
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeToNull();
        });
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const optionsWithDisabled = [
    {
      label: "Monday",
      value: "Monday",
      disabled: false,
    },
    {
      label: "Tuesday",
      value: "Tuesday",
      disabled: false,
    },
    {
      label: "Wednesday",
      value: "Wednesday",
      disabled: false,
    },
    {
      label: "Thursday",
      value: "Thursday",
      disabled: false,
    },
    {
      label: "Friday",
      value: "Friday",
      disabled: false,
    },
    {
      label: "Saturday",
      value: "Saturday",
      disabled: false,
    },
    {
      label: "Sunday",
      value: "Sunday",
      disabled: false,
    },
  ];

  return (
    <>
      <Form
        form={form}
        // initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        style={{ marginTop: 40 }}
      >
        <Row justify={"space-between"}></Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Schedule:"
              name="shiftCodeId"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                placeholder="Choose Schedule..."
                allowClear
                options={[
                  { value: "daily", label: "Daily" },
                  { value: "weekend", label: "Weekend" },
                  { value: "custom", label: "Custom" },
                ]}
                onChange={handleSelectChange}
                onClear={() => {
                  // Set check to true when the selection is cleared
                  setCheck(true);
                }}
              />
            </Form.Item>

            {selectedOption === "custom" && (
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label="Custom Name"
                    name="customName"
                    rules={[
                      { required: true, message: "Please enter a custom name" },
                    ]}
                    style={{ width: "100%", border: 1 }}
                  >
                    <Input
                      placeholder="Enter custom name"
                      onChange={(e) => setCustomName(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={12}>
            <Form.Item
              label=""
              name="options"
              labelAlign="left"
              style={{ marginTop: 30 }}
            >
              {optionsWithDisabled.map((option) => (
                <div
                  key={option.value}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      marginBottom: "8px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      height: "5vh",
                      width: "100%",
                      alignContent: "center",
                      paddingLeft: "10px",
                      backgroundColor: `${
                        checkboxState[option.value] ? "#fff1e6" : "white"
                      }`,
                    }}
                  >
                    <Checkbox
                      value={option.value}
                      onChange={(e) => handleCheckboxChange(e, option.value)}
                      checked={checkboxState[option.value]}
                      disabled={!check}
                    >
                      <span
                        style={{
                          color: check ? "black" : "grey",
                          opacity: check ? 1 : 0.6,
                        }}
                      >
                        {option.label}
                      </span>
                      <span> </span>
                      {selectedOption === "daily" &&
                        [
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                        ].includes(option.value) && (
                          <>
                            <span>{selectedTimeIn}</span> -{" "}
                            <span>{selectedTimeOut}</span>
                          </>
                        )}
                      {selectedOption === "weekend" &&
                        ["Saturday", "Sunday"].includes(option.value) && (
                          <>
                            <span>{selectedTimeIn}</span> -{" "}
                            <span>{selectedTimeOut}</span>
                          </>
                        )}
                      {selectedOption === "custom" && (
                        <span style={{ marginLeft: "20px" }}>
                          <span>{customTimes[option.value].in}</span> -{" "}
                          <span>{customTimes[option.value].out}</span>
                        </span>
                      )}
                    </Checkbox>
                  </div>
                  {selectedOption ? (
                    selectedOption === "custom" ? (
                      <CustomTimePickerBtn day={option.value} />
                    ) : (
                      <TimePickerBtn />
                    )
                  ) : null}
                  {/* {selectedOption === "custom" ? (
                    <CustomTimePickerBtn day={option.value} />
                  ) : (
                    <TimePickerBtn />
                  )} */}
                </div>
              ))}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={20} justify={"center"} style={{ marginTop: 20 }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
                onClick={() => setCreateShiftModalVisible(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(ShiftForm);
