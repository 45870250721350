import React from "react";
import { Row, Col, Button } from "antd";
//import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { toJS } from "mobx";
//import { useMutation, useQueryClient } from "react-query";
import { useEmployee } from "../../../app/hooks/useEmployee";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const ReviewinfoForm = ({ onPrev, onSubmit }) => {
  const { employeeStore, modalStore } = useStore();
  const {
    //image,
    // setImage,
    // setFileList,
    personalInfoFormData,
    //physicalInfoFormData,
    //skillsInfoFormData,
    employmentInfoFormData,
    salaryInfoFormData,
    //addEmployeeData,
    setDataBackToEmpty,
    setCoreFormCurrentStep,
    //filterEmployeesByStatus,
    employeeObject,
    setEmployeeId
  } = employeeStore;
  const { firstName, middleName, lastName, birthDate, email, address, gender } =
    personalInfoFormData;
  const {
    //position,
    employeeNum,
    dateHired,
    employmentStatus,
    preEmployRequirements,
    activeOrInactive,
  } = employmentInfoFormData;
  const { basicPay, allowances, netOfTax, vatableAmount } = salaryInfoFormData;
  const { create } = useEmployee();
  const { setSubCoreModalVisible, setUpdatePhotoModalVisible } = modalStore;

  //const queryClient = useQueryClient();

  // Define a mutation function using useMutation
  // const { mutate: submitFormMutation } = useMutation(
  //   async (formDataToSend) => {
  //     // Simulate form submission or call an API
  //     // Example: await axios.post("your-server-endpoint", formDataToSend);

  //     formDataToSend.personalInfo.status = "core";
  //     formDataToSend.personalInfo.image = image;

  //     // Handle the response from the server, e.g., show a success message
  //     addEmployeeData(formDataToSend);
  //   },
  //   {
  //     // Optional configuration
  //     onSettled: (data, error, variables) => {
  //       // This callback is executed whether the mutation succeeds or fails
  //       setSubCoreModalVisible(false);
  //       setDataBackToEmpty();
  //       setCoreFormCurrentStep(0);
  //       setImage("");
  //       setFileList([]);

  //       // Trigger a refetch of the "employeesList" query to update EmployeeCards
  //       queryClient.invalidateQueries("employeesList");

  //       if (variables.personalInfo.status === "core") {
  //         filterEmployeesByStatus("core");
  //       }
  //     },
  //   }
  // );

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const formDataToSend = {
    //   personalInfo: toJS(personalInfoFormData),
    //   physicalInfo: toJS(physicalInfoFormData),
    //   skillsInfo: toJS(skillsInfoFormData),
    //   employmentInfo: toJS(employmentInfoFormData),
    //   salaryInfo: toJS(salaryInfoFormData),
    // };
    // const missingFields = Object.keys(employeeObject).filter(
    //   (key) => !employeeObject[key]
    // );

    // if (missingFields.length > 0) {
    //   // Log missing fields to console
    //   console.log("Missing fields:", missingFields);

    //   // Display an error message or prevent form submission
    //   toast.error("Please fill in all required fields", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    // Append data to FormData
    const formData = new FormData();
    Object.entries(toJS(employeeObject)).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      //submitFormMutation(formDataToSend);
      //const formattedJSON = JSON.stringify(employeeObject, null, 2);
      //const formattedJSON = JSON.stringify(employeeObject);

      await create(employeeObject)
        .then((data) => {
          console.log(data.employeePersonalProfile.employeeId)
          setEmployeeId(data?.employeePersonalProfile?.employeeId)
          setSubCoreModalVisible(false);
          setUpdatePhotoModalVisible(true);
          setDataBackToEmpty();
          setCoreFormCurrentStep(0);
          //setImage("");
          //setFileList([]);
          onSubmit();
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          // Check if the error contains response data
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            // Extract error titles and display them in toasts
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            // Display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      // Handle any errors, e.g., show an error message
      console.error("Error submitting data:", error);
    }
  };

  ReviewinfoForm.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h2 className="core-form-title">
              I. <span style={{ color: "#FF7201" }}>Personal </span>Information
            </h2>
            <h3>
              Name: {firstName} {middleName} {lastName}
            </h3>
            <h3>Birth Date: {birthDate}</h3>
            <h3>Gender: {gender}</h3>
            <h3>Email: {email}</h3>
            <h3>Address: {address}</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2 className="core-form-title">
              II. <span style={{ color: "#FF7201" }}>Employment </span>
              Information
            </h2>
            <h3>Employee Number: {employeeNum}</h3>
            <h3>Date Hired: {dateHired}</h3>
            <h3>Employment Status: {employmentStatus}</h3>
            <h3>Pre Employment Status: {preEmployRequirements}</h3>
            <h3>Status: {activeOrInactive}</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2 className="core-form-title">
              II. <span style={{ color: "#FF7201" }}>Salary </span>Information
            </h2>
            <h3>Basic Pay: {basicPay}</h3>
            <h3>Allowances: {allowances}</h3>
            <h3>Net of Tax: {netOfTax}</h3>
            <h3>Vatable Amount: {vatableAmount}</h3>
          </Col>
        </Row>

        <Row gutter={20} justify={"center"}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#F47F20",
                borderRadius: "83px",
                width: "123.87px",
              }}
            >
              Save
            </Button>
          </Col>
          <Col>
            <Button
              type="default"
              onClick={onPrev}
              style={{
                backgroundColor: "#2C2C2C",
                borderRadius: "83px",
                width: "123.87px",
                color: "#ffff",
              }}
            >
              Back
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ReviewinfoForm;
