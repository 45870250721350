import useSWR from "swr";
import { api } from "../../services";

const BASE_URL = "/utility/enums/ot-status";

export const useOvertimeStatus = () => {
  const { data, isLoading, mutate } = useSWR(BASE_URL, api.get);
  return {
    isLoading,
    data,
    count: data?.length,
    mutate,
  };
};
