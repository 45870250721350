import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Layout, Dropdown, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../assets/images/third-logo.png";
import PropTypes from "prop-types";
import { useCurrentUser } from "../../app/hooks/useCurrentUser";
import { logout } from "../services/auth";
import SearchBar from "../../components/common/searchBar";

const { Header, Content } = Layout;

const PrivateLayout = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLinkClick = () => {
    setIsDrawerOpen(false);
  };
  //const location = useLocation();
  const { currentUser, isLoading, role } = useCurrentUser();

  PrivateLayout.propTypes = {
    children: PropTypes.node.isRequired, // Assuming children is a React node, adjust as needed
  };

  const items = [
    {
      key: "1",
      label: (
        <p rel="noopener noreferrer" onClick={logout}>
          Logout
        </p>
      ),
    },
  ];

  if (isLoading || !currentUser?.isLoggedIn) return <></>;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div className="navigationContainer">
        <Header
          className="text-white p-4 py-4 flex justify-between items-center container mx-auto"
          style={{ backgroundColor: "#2C2C2C" }}
        >
          <div className="flex items-start">
            <button
              className="text-xl mr-4 lg:hidden"
              onClick={toggleDrawer}
              style={{ color: "black" }}
            >
              &#9776;
            </button>
            <a href="/" rel="noopener noreferrer">
              <img
                src={Logo}
                alt="Your Logo"
                style={{ width: "200px" }}
                className="flex justify-start lg:mr-40"
              />
            </a>
          </div>
          <nav className="hidden lg:flex space-x-10">
            <Link to="/" style={{ color: "white" }}>
              Dashboard
            </Link>
            <Link to="/event-request" style={{ color: "white" }}>
              Manpower/Event Request
            </Link>
            {role.includes("Project Manager") ? null : (
              <Link to="/employees" style={{ color: "white" }}>
                Employees
              </Link>
            )}
            <Link to="/user-management" style={{ color: "white" }}>
              User Management
            </Link>
          </nav>
          <Row gutter={20} justify={"space-between"}>
          <Col style={{ marginLeft: '10px' }}>
            <SearchBar />
            </Col>
            <Col>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 13,
                    overflow: "hidden",
                  }}
                >
                  {currentUser.user.employeeInfo != null ? (
                    <img
                      src={currentUser.user.employeeInfo.profileImagePath}
                      alt="USER"
                      style={{ height: "40px", width: "40px" }}
                      className="m-0 p-0 "
                    />
                  ) : (
                    <UserOutlined
                      style={{ fontSize: "24px", color: "black" }}
                    />
                  )}
                </div>
              </Dropdown>
            </Col>
          </Row>
        </Header>
      </div>
      {isDrawerOpen && (
        <div
          className="lg:hidden fixed left-0 top-0 w-64 h-full bg-gray-800 text-white p-4"
          style={{
            transition: "transform 0.3s ease-in-out",
            transform: "translateX(0)",
            opacity: 1.9,
            zIndex: 5,
          }}
        >
          <button
            className="text-2xl absolute top-4 right-4"
            onClick={toggleDrawer}
            style={{ color: "white" }}
          >
            &#10005;
          </button>
          <nav className="flex flex-col space-y-4 mt-12">
            <Link to="/" style={{ color: "white" }} onClick={handleLinkClick}>
              Dashboard
            </Link>
            <Link
              to="/event-request"
              style={{ color: "white" }}
              onClick={handleLinkClick}
            >
              Manpower/Event Request
            </Link>
            <Link
              to="/employees"
              style={{ color: "white" }}
              onClick={handleLinkClick}
            >
              Employees
            </Link>
            <Link
              to="/user-management"
              style={{ color: "white" }}
              onClick={handleLinkClick}
            >
              User Management
            </Link>
          </nav>
        </div>
      )}
      <Content
        style={{
          margin: "24px 16px",
          padding: 20,
          minHeight: 280,
        }}
      >
        <Outlet>{children}</Outlet>
      </Content>
    </Layout>
  );
};

export default PrivateLayout;
