import useSWR from "swr";

import { api } from "../services";

const BASE_URL = "/system-admin/employees";
const PATCH_URL = "/system-admin/employees/upload-image";
const UPDATE_URL = "/system-admin/employees/update-status";

export const useEmployee = (query = {}) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);
  const create = async (data) => {
    const employee = await api.post(BASE_URL, data);
    return employee;
  };
  const update = async (data) => {
    const employee = await api.put(BASE_URL, data);
    return employee;
  };

  const updateEmployeeStatus = async (data) => {
    const employee = await api.patch(UPDATE_URL, data);
    return employee;
  };
  const patch = async (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const employee = await api.post(PATCH_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return employee;
  };
  const find = async (query) => {
    const employee = await api.get(`${BASE_URL}/find?search=${query}`);
    return employee;
  };
  return {
    isLoading,
    data: data,
    count: data?.length,
    create,
    update,
    updateEmployeeStatus,
    patch,
    find,
    mutate,
  };
};

export const useEmployeeById = (id) => {
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}/${id}`, api.get);

  const remove = async (id) => {
    const employee = await api.delete(`${BASE_URL}/${id}`);
    return employee;
  };
  const currentEmployment = data?.serviceRecords.find((item) => {
    return item.status == 1;
  });
  const previousEmloyment = data?.serviceRecords.filter((item) => {
    return item.status == 0;
  });
  return {
    isLoading,
    data,
    employeePersonalProfile: data?.employeePersonalProfile,
    currentEmployment,
    previousEmloyment:
      previousEmloyment && previousEmloyment.length > 0
        ? previousEmloyment[0]
        : null,
    mutate,
    remove,
  };
};
