import React, { useState, useEffect } from "react";
import { Input, List, Button, Skeleton } from "antd";
import { useEmployee } from "../../app/hooks/useEmployee";
import { debounce } from 'lodash';
import EmployeeDetailsModal from "../../components/employees/modal/EmployeeDetailsModal"; 
import InfiniteScroll from 'react-infinite-scroll-component';

const SearchBar = () => {

  const {find } = useEmployee();
const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);

const searchEmployeeList = async (query) => {
  const res = await find(query);
  setFilteredEmployeeList(res);
}

const [searchQuery, setSearchQuery] = useState('');

useEffect(() => {
  if (searchQuery) {
    handleSearch();
  }
}, [searchQuery]);


const [openModals, setOpenModals] = useState({});

const showModal = (employeeId) => {
  setOpenModals(prevState => ({
    ...prevState,
    [employeeId]: true
  }));
};

const handleCancel = (employeeId) => {
  setOpenModals(prevState => ({
    ...prevState,
    [employeeId]: false
  }));
  setSearchQuery('');
};

const handleSearch = debounce(() => {
  searchEmployeeList(searchQuery);
}, 1000);

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
}
  return (
  <>
    <Input placeholder="Search" onChange={handleSearchChange} value={searchQuery}/>
    {searchQuery && (
      <div
        id="scrollableDiv"
        style={{
          maxHeight: 300,
          width: 180,
          zIndex: 100,
          position: 'absolute',
          overflowY: 'auto',
          borderRadius: '5px',
          border: '1px solid rgba(140, 140, 140, 0.35)',
          backgroundColor: 'white',
        }}
      >

        <InfiniteScroll
          dataLength={filteredEmployeeList ? filteredEmployeeList?.length : 0}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget="scrollableDiv"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={filteredEmployeeList ? filteredEmployeeList : []}
            renderItem={(item) => (
              <List.Item
                style={{
                  padding: '0',
                }}
              >
                <div style={{ flex: 1 }}>
                  <Button
                    block={true}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    onClick={() => showModal(item.id)}
                  >
                    {item.firstName} {item.lastName}
                  </Button>
                  {openModals[item.id] && (
                    <EmployeeDetailsModal
                      id={item.id}
                      handleCancel={() => handleCancel(item.id)} />
                  )}
                </div>
              </List.Item>
            )} />
        </InfiniteScroll>
      </div>
    )}
  </>
  )
}

export default SearchBar;