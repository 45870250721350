import { makeAutoObservable, runInAction, toJS } from "mobx";

export default class EmployeeStore {
  serviceRecords = [];
  previousServiceRecord = null;
  employeeId = "";

  
  personalInfoFormData = {
    profileImagePath: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    email: "",
    sssNum: "",
    address: "",
    philHealthNum: "",
    gender: "",
    contactNum: "",
    preference: "",
    civilStatus: "",
    pagIbigNum: "",
    tinNum: "",
    guardianName: "",
    guardianContactNum: "",
    restriction: "",
  };

  physicalInfoFormData = {
    withTattoo: "",
    remarks: "",
    teethRemarks: "",
    height: 0,
    weight: 0,
    hairColor: "",
    typeRemarks: "",
    bodyFrame: "",
    shirtSize: "",
    skinComplexion: "",
    eyeSightRemarks: "",
    physicalRemarks: "",
    speechRemarks: "",
    dialect: "",
    bodyMarks: "",
  };

  skillsInfoFormData = {
    computerProficiency: "",
    narration: "",
    understanding: "",
    connection: "",
    pronunciation: "",
    diction: "",
    personalityRemarks: "",
    enthusiasm: "",
  };

  employmentInfoFormData = {
    position: "",
    employeeNum: "",
    dateHired: "",
    workAss: "",
    employmentStatus: "",
    preEmployRequirements: "",
    activeOrInactive: "",
    department: "",
    contractType: "",
  };

  salaryInfoFormData = {
    basicPay: "",
    allowances: "",
    fringeBenefits: "",
    vatableAmount: 0,
    netOfTax: "",
    inflationFields: [
      {
        inflation: null,
        rate: null,
      },
    ],
    hmo: "",
    loans: "",
    carLoan: "",
    cashAdvance: "",
    suspension: "",
    accountability: "",
    sssRemittance: "",
    pagIbigRemittance: "",
    philHealthRemittance: "",
    sss: "",
    pagIbig: "",
  };

  manpowerEmploymentInforFormData = {
    manPowerPosition: "",
    manPowerEmployeeNum: "",
    manPowerDepartments: "",
    manPowerDateHired: "",
    manPowerWorkAss: "",
    manPowerPreEmployRequirements: [],
    manPowerActiveOrInactive: "",
    manPowerEmploymentStatus: "",
    manPowerPreEmployRequirementsStatus: "",
    manPowercontractType: "",
  };

  manpowerSalaryDetailsFormData = {
    incomeProvisions: "",
    incomeRateCard: "",
    suspension: "",
    accountability: "",
    governSSSRemi: "",
    govenrPagIbigRemi: "",
    governPhilHealthRemi: "",
  };

  employeesList = [];

  manPowerFormCurrentStep = 0;
  coreFormCurrentStep = 0;

  isDataLoading = false;

  image = "";
  fileList = [];
  coreEmployeeList = [];
  manPowerEmployeeList = [];

  currentDate = new Date();

  uploadPhotoObject = {
    id : this.employeeId,
    "EmployeePhoto": this.image,
  }

  formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}


  employeeObject = {
    employeeNumber:
      this.employmentInfoFormData.employeeNum ||
      this.manpowerEmploymentInforFormData.manPowerEmployeeNum,
    firstName: this.personalInfoFormData.firstName,
    profileImagePath: this.personalInfoFormData.profileImagePath,
    middleName: this.personalInfoFormData.middleName,
    lastName: this.personalInfoFormData.lastName,
    email: this.personalInfoFormData.email,
    employeeStatus:
      this.employmentInfoFormData.activeOrInactive ||
      this.manpowerEmploymentInforFormData.manPowerActiveOrInactive,
    phoneNumber: this.personalInfoFormData.contactNum,
    dateHired : this.employmentInfoFormData.dateHired || this.manpowerEmploymentInforFormData.manPowerDateHired,
    isLoanAllowed: true,
    userName: this.employmentInfoFormData.employeeNum ||
    this.manpowerEmploymentInforFormData.manPowerEmployeeNum,
    employeePersonalProfile: {
      birthDate: this.personalInfoFormData.birthDate,
      presentAddress: this.personalInfoFormData.address,
      permanentddress: this.personalInfoFormData.address,
      civilStatus: this.personalInfoFormData.civilStatus,
      emergencyContactName: this.personalInfoFormData.guardianName,
      emergencyContactNumber: this.personalInfoFormData.guardianContactNum,
      emergencyContactRelationship: this.personalInfoFormData.restriction,
      sSSNumber: this.personalInfoFormData.sssNum,
      philhealthNumber: this.personalInfoFormData.philHealthNum,
      pagIbigNumber: this.personalInfoFormData.pagIbigNum,
      tinNumber: this.personalInfoFormData.tinNum,
      restrictionRemarks: this.personalInfoFormData.restriction,
      gender: this.personalInfoFormData.gender,
      genderPreferenceRemarks: this.personalInfoFormData.preference,
      dialectSpoken: this.physicalInfoFormData.dialect,
    },
    employeePhysicalProfile: {
      height: this.physicalInfoFormData.height,
      weight: this.physicalInfoFormData.weight,
      hairColor: this.physicalInfoFormData.hairColor,
      hairType: this.physicalInfoFormData.typeRemarks,
      withTattoo: Boolean(this.physicalInfoFormData.withTattoo),
      tattooRemarks: this.physicalInfoFormData.remarks,
      teethRemarks: this.physicalInfoFormData.teethRemarks,
      bodyFrame: this.physicalInfoFormData.bodyFrame,
      shirtSize: this.physicalInfoFormData.shirtSize,
      skinComplexion: this.physicalInfoFormData.skinComplexion,
      isPoorEyesight: Boolean(this.physicalInfoFormData.eyeSightRemarks),
      speechRemarks: this.physicalInfoFormData.speechRemarks,
      distinguishingBodyMarks: this.physicalInfoFormData.bodyMarks,
      physicalDeformityRemarks: this.physicalInfoFormData.physicalRemarks,
    },
    employeeSkill: {
      computerLiteracy: this.skillsInfoFormData.computerProficiency,
      remarks: "test",
    },
    employeeComprehensionSkill: {
      narration: this.skillsInfoFormData.narration,
      understanding: this.skillsInfoFormData.understanding,
      connection: this.skillsInfoFormData.connection,
    },
    employeeCommunicationSkill: {
      pronounciation: this.skillsInfoFormData.pronunciation,
      diction: this.skillsInfoFormData.diction,
      personalityRemarks: this.skillsInfoFormData.personalityRemarks,
      enthusiam: this.skillsInfoFormData.enthusiasm,
    },
    serviceRecords: [
      {
        departmentId:
          this.employmentInfoFormData.department ||
          this.manpowerEmploymentInforFormData.manPowerDepartments,
        positionId:
          this.employmentInfoFormData.position ||
          this.manpowerEmploymentInforFormData.manPowerPosition,
        regionId:
          this.employmentInfoFormData.workAss ||
          this.manpowerEmploymentInforFormData.manPowerWorkAss,
          dateHired : this.formatDate(this.employmentInfoFormData.dateHired || this.manpowerEmploymentInforFormData.manPowerDateHired),
        // departmentName: this.employmentInfoFormData.department,
        // positionName:
        //   this.employmentInfoFormData.position ||
        //   this.manpowerEmploymentInforFormData.manPowerPosition,
        employmentStatus:
          this.employmentInfoFormData.employmentStatus ||
          this.manpowerEmploymentInforFormData.manPowerEmploymentStatus,
        preEmploymentRequirementStatus: Boolean(
          this.employmentInfoFormData.preEmployRequirements ||
            this.manpowerEmploymentInforFormData
              .manPowerPreEmployRequirementsStatus
        ),
        contractType:
          this.employmentInfoFormData.contractType ||
          this.manpowerEmploymentInforFormData.manPowercontractType,
        status: 1,
        employmentSalary: {
          basicPay: 0,
          netOfTax: true,
          vatableAmount: 0,
          addOnProvision: 0,
          approvedRateCard: 0,
        },
      },
    ],
    employeeCompensations: [
      {
        compensationPlanId: "b7c9c176-f5cc-4e84-aae1-e215cc300830",
        amount: 0,
        paidAmount: 0,
        status: 0,
      },
    ],
  };

  constructor() {
    makeAutoObservable(this);
  }

  updateEmployeeObject = () => {
    runInAction(() => {
      this.employeeObject = {
        employeeNumber:
          this.employmentInfoFormData.employeeNum ||
          this.manpowerEmploymentInforFormData.manPowerEmployeeNum,
        firstName: this.personalInfoFormData.firstName,
        middleName: this.personalInfoFormData.middleName,
        lastName: this.personalInfoFormData.lastName,
        profileImagePath: this.personalInfoFormData.profileImagePath,
        email: this.personalInfoFormData.email,
        employeeStatus:
          this.employmentInfoFormData.activeOrInactive ||
          this.manpowerEmploymentInforFormData.manPowerActiveOrInactive,
        phoneNumber: this.personalInfoFormData.contactNum,
        dateHired : this.employmentInfoFormData.dateHired || this.manpowerEmploymentInforFormData.manPowerDateHired,
        isLoanAllowed: true,
        userName: this.employmentInfoFormData.employeeNum ||
        this.manpowerEmploymentInforFormData.manPowerEmployeeNum,
        employeePersonalProfile: {
          birthDate: this.personalInfoFormData.birthDate,
          presentAddress: this.personalInfoFormData.address,
          permanentddress: this.personalInfoFormData.address,
          civilStatus: this.personalInfoFormData.civilStatus,
          emergencyContactName: this.personalInfoFormData.guardianName,
          emergencyContactNumber: this.personalInfoFormData.guardianContactNum,
          emergencyContactRelationship: this.personalInfoFormData.restriction,
          sSSNumber: this.personalInfoFormData.sssNum,
          philhealthNumber: this.personalInfoFormData.philHealthNum,
          pagIbigNumber: this.personalInfoFormData.pagIbigNum,
          tinNumber: this.personalInfoFormData.tinNum,
          restrictionRemarks: this.personalInfoFormData.restriction,
          gender: this.personalInfoFormData.gender,
          genderPreferenceRemarks: this.personalInfoFormData.preference,
          dialectSpoken: this.physicalInfoFormData.dialect,
        },
        employeePhysicalProfile: {
          height: this.physicalInfoFormData.height,
          weight: this.physicalInfoFormData.weight,
          hairColor: this.physicalInfoFormData.hairColor,
          hairType: this.physicalInfoFormData.typeRemarks,
          withTattoo: Boolean(this.physicalInfoFormData.withTattoo),
          tattooRemarks: this.physicalInfoFormData.remarks,
          teethRemarks: this.physicalInfoFormData.teethRemarks,
          bodyFrame: this.physicalInfoFormData.bodyFrame,
          shirtSize: this.physicalInfoFormData.shirtSize,
          skinComplexion: this.physicalInfoFormData.skinComplexion,
          isPoorEyesight: Boolean(this.physicalInfoFormData.eyeSightRemarks),
          speechRemarks: this.physicalInfoFormData.speechRemarks,
          distinguishingBodyMarks: this.physicalInfoFormData.bodyMarks,
          physicalDeformityRemarks: this.physicalInfoFormData.physicalRemarks,
        },
        employeeSkill: {
          computerLiteracy: this.skillsInfoFormData.computerProficiency,
          remarks: "test",
        },
        employeeComprehensionSkill: {
          narration: this.skillsInfoFormData.narration,
          understanding: this.skillsInfoFormData.understanding,
          connection: this.skillsInfoFormData.connection,
        },
        employeeCommunicationSkill: {
          pronounciation: this.skillsInfoFormData.pronunciation,
          diction: this.skillsInfoFormData.diction,
          personalityRemarks: this.skillsInfoFormData.personalityRemarks,
          enthusiam: this.skillsInfoFormData.enthusiasm,
        },
        serviceRecords: [
          {
            departmentId:
              this.employmentInfoFormData.department ||
              this.manpowerEmploymentInforFormData.manPowerDepartments,
            positionId:
              this.employmentInfoFormData.position ||
              this.manpowerEmploymentInforFormData.manPowerPosition,
            regionId:
              this.employmentInfoFormData.workAss ||
              this.manpowerEmploymentInforFormData.manPowerWorkAss,
              dateHired : this.formatDate(this.employmentInfoFormData.dateHired || this.manpowerEmploymentInforFormData.manPowerDateHired),
            // departmentName: this.employmentInfoFormData.department,
            // positionName:
            //   this.employmentInfoFormData.position ||
            //   this.manpowerEmploymentInforFormData.manPowerPosition,
            employmentStatus:
              this.employmentInfoFormData.employmentStatus ||
              this.manpowerEmploymentInforFormData.manPowerEmploymentStatus,
            preEmploymentRequirementStatus: Boolean(
              this.employmentInfoFormData.preEmployRequirements ||
                this.manpowerEmploymentInforFormData
                  .manPowerPreEmployRequirementsStatus
            ),
            contractType:
              this.employmentInfoFormData.contractType ||
              this.manpowerEmploymentInforFormData.manPowercontractType,
            status: 1,
            employmentSalary: {
              basicPay: 0,
              netOfTax: true,
              vatableAmount: 0,
              addOnProvision: 0,
              approvedRateCard: 0,
            },
            preEmploymentRequirements: [
              {
                fileName: "string",
                filePath: "string",
                remarks: "string",
                requirementStatus: "NotApplicable",
              },
            ],
          },
        ],
        employeeCompensations: [
          {
            compensationPlanId: "b7c9c176-f5cc-4e84-aae1-e215cc300830",
            amount: 0,
            paidAmount: 0,
            status: 0,
          },
        ],
      };
    });
  };

  updateUploadPhotoObject = () => {
    runInAction(()=>{
      this.uploadPhotoObject = {
        id : this.employeeId,
        "EmployeePhoto": this.image,
      }
      console.log(toJS(this.uploadPhotoObject));
    })
  }

  addServiceRecord = (record) => {
    runInAction(() => {
      this.previousServiceRecord =
        this.serviceRecords.length > 0
          ? { ...this.serviceRecords[this.serviceRecords.length - 1] }
          : null;
      this.serviceRecords.push(record);
      console.log(toJS(this.serviceRecords));
    });
  };

  undoLastServiceRecord = () => {
    runInAction(() => {
      this.serviceRecords.pop();
      this.previousServiceRecord = null;
    });
  };

  filterEmployeesByStatus = (status) => {
    runInAction(() => {
      // Filter employeesList based on the provided status
      const filteredEmployees = this.employeesList.filter(
        (employee) => employee.results.personalInfo.status === status
      );

      // If the status is "core", update coreEmployeeList
      if (status === "core") {
        this.coreEmployeeList = filteredEmployees;
      } else if (status === "projectBase") {
        this.manPowerEmployeeList = filteredEmployees;
      }

      // You can add more conditions for other statuses if needed

      // Example: If the status is "anotherStatus", update anotherStatusEmployeeList
      // if (status === "anotherStatus") {
      //   this.anotherStatusEmployeeList = filteredEmployees;
      // }
    });
  };

  setDataLoading = (bool) => {
    runInAction(() => {
      this.isDataLoading = bool;
    });
  };

  setFileList = (value) => {
    runInAction(() => {
      this.fileList = value;
    });
  };

  setImage = (id, filePath) => {
    runInAction(() => {
      this.employeeId = id;
      this.image = filePath;
      console.log(filePath);
      console.log(id);
      this.updateUploadPhotoObject();
      //console.log(toJS(this.uploadPhotoObject))
    });
  };

  updateEmployeeStatus = (id, selectedStatus) => {
    runInAction(() => {
      // Find the employee with the given ID in the employeesList array
      const employee = this.employeesList.find((item) => item.id === id);

      if (employee) {
        // Update the status field inside personalInfo
        employee.results.personalInfo.status = selectedStatus;
        this.setDataLoading(true);
      }
      this.setDataLoading(false);
    });
  };

  addEmployeeData = (results) => {
    const newItem = { id: Date.now(), results };
    this.setDataLoading(true);

    runInAction(() => {
      this.employeesList.push(newItem);
      this.setDataLoading(false);
    });
  };

  setPersonalInfo = (values) => {
    runInAction(() => {
      // Update the personalInfoFormData with the provided values
      this.personalInfoFormData = { ...this.personalInfoFormData, ...values };
      this.updateEmployeeObject();
      // console.log(toJS(this.employeeObject));
    });
  };

  setPhysicalInfo = (values) => {
    runInAction(() => {
      this.physicalInfoFormData = { ...this.physicalInfoFormData, ...values };
      this.updateEmployeeObject();
      // console.log(toJS(this.employeeObject));
    });
  };

  setSkillInfo = (values) => {
    runInAction(() => {
      this.skillsInfoFormData = { ...this.skillsInfoFormData, ...values };
      this.updateEmployeeObject();
      // console.log(toJS(this.employeeObject));
    });
  };

  setEmploymentInfo = (values) => {
    runInAction(() => {
      this.employmentInfoFormData = {
        ...this.employmentInfoFormData,
        ...values,
      };
      this.updateEmployeeObject();
      // console.log(toJS(this.employeeObject));
      // console.log(toJS(this.employmentInfoFormData));
    });
  };

  setSalaryInfo = (values) => {
    runInAction(() => {
      this.salaryInfoFormData = { ...this.salaryInfoFormData, ...values };
      this.updateEmployeeObject();
      // console.log(toJS(this.employeeObject));
      // console.log(toJS(this.salaryInfoFormData));
    });
  };

  //Manpower Form
  setManpowerEmploymentInfo = (values) => {
    runInAction(() => {
      this.manpowerEmploymentInforFormData = {
        ...this.manpowerEmploymentInforFormData,
        ...values,
      };
      this.updateEmployeeObject();
      // console.log(toJS(this.employeeObject));
    });
  };
  //Manpower Form
  setSalaryDetailInfo = (values) => {
    runInAction(() => {
      this.manpowerSalaryDetailsFormData = {
        ...this.manpowerSalaryDetailsFormData,
        ...values,
      };
      this.updateEmployeeObject();
      //console.log(toJS(this.employeeObject));
    });
  };

  //When modal is close
  setDataBackToEmpty = () => {
    runInAction(() => {
      this.personalInfoFormData = {}; // Revert to the initial state
      this.physicalInfoFormData = {};
      this.skillsInfoFormData = {};
      this.employmentInfoFormData = {};
      this.salaryInfoFormData = {};
      this.manpowerEmploymentInforFormData = {};
      this.manpowerSalaryDetailsFormData = {};
    });
  };

  setManpowerFormCurrentStep = (number) => {
    runInAction(() => {
      this.manPowerFormCurrentStep = number;
    });
  };

  setCoreFormCurrentStep = (number) => {
    runInAction(() => {
      this.coreFormCurrentStep = number;
    });
  };

  setEmployeeId = (id) => {
    runInAction(()=>{
      this.employeeId = id;
      //console.log("Hello this the ID", this.employeeId);
    })
  }
}
