import React from "react";
import { Row, Col, Layout } from "antd";

import OvertimeBody from "../../components/overtime/OvertimeBody";

const Overtime = () => {
  return (
    <>
      <Layout style={{ minHeight: "80vh", padding: "20px" }}>
        <Row justify={"center"}>
          <Col>
            <OvertimeBody />
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default Overtime;
